export const AccountMenuList = () => import('../../components/account/MenuList.vue' /* webpackChunkName: "components/account-menu-list" */).then(c => wrapFunctional(c.default || c))
export const BaseBackdropOverlay = () => import('../../components/base/BackdropOverlay.vue' /* webpackChunkName: "components/base-backdrop-overlay" */).then(c => wrapFunctional(c.default || c))
export const BaseButton = () => import('../../components/base/Button.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c))
export const BaseCheckbox = () => import('../../components/base/Checkbox.vue' /* webpackChunkName: "components/base-checkbox" */).then(c => wrapFunctional(c.default || c))
export const BaseCollapse = () => import('../../components/base/Collapse.vue' /* webpackChunkName: "components/base-collapse" */).then(c => wrapFunctional(c.default || c))
export const BaseCollapseItem = () => import('../../components/base/CollapseItem.vue' /* webpackChunkName: "components/base-collapse-item" */).then(c => wrapFunctional(c.default || c))
export const BaseCollapseOptions = () => import('../../components/base/CollapseOptions.vue' /* webpackChunkName: "components/base-collapse-options" */).then(c => wrapFunctional(c.default || c))
export const BaseCollapseTransition = () => import('../../components/base/CollapseTransition.vue' /* webpackChunkName: "components/base-collapse-transition" */).then(c => wrapFunctional(c.default || c))
export const BaseForm = () => import('../../components/base/Form.vue' /* webpackChunkName: "components/base-form" */).then(c => wrapFunctional(c.default || c))
export const BaseFormItem = () => import('../../components/base/FormItem.vue' /* webpackChunkName: "components/base-form-item" */).then(c => wrapFunctional(c.default || c))
export const BaseIcon = () => import('../../components/base/Icon.vue' /* webpackChunkName: "components/base-icon" */).then(c => wrapFunctional(c.default || c))
export const BaseImageZoomer = () => import('../../components/base/ImageZoomer.vue' /* webpackChunkName: "components/base-image-zoomer" */).then(c => wrapFunctional(c.default || c))
export const BaseInfiniteLoading = () => import('../../components/base/InfiniteLoading.vue' /* webpackChunkName: "components/base-infinite-loading" */).then(c => wrapFunctional(c.default || c))
export const BaseInput = () => import('../../components/base/Input.vue' /* webpackChunkName: "components/base-input" */).then(c => wrapFunctional(c.default || c))
export const BaseInputNumber = () => import('../../components/base/InputNumber.vue' /* webpackChunkName: "components/base-input-number" */).then(c => wrapFunctional(c.default || c))
export const BaseLightBox = () => import('../../components/base/LightBox.vue' /* webpackChunkName: "components/base-light-box" */).then(c => wrapFunctional(c.default || c))
export const BaseLink = () => import('../../components/base/Link.vue' /* webpackChunkName: "components/base-link" */).then(c => wrapFunctional(c.default || c))
export const BaseMap = () => import('../../components/base/Map.vue' /* webpackChunkName: "components/base-map" */).then(c => wrapFunctional(c.default || c))
export const BaseModal = () => import('../../components/base/Modal.vue' /* webpackChunkName: "components/base-modal" */).then(c => wrapFunctional(c.default || c))
export const BaseModalLayout = () => import('../../components/base/ModalLayout.vue' /* webpackChunkName: "components/base-modal-layout" */).then(c => wrapFunctional(c.default || c))
export const BaseNIXModal = () => import('../../components/base/NIXModal.vue' /* webpackChunkName: "components/base-n-i-x-modal" */).then(c => wrapFunctional(c.default || c))
export const BaseNIXModalLayout = () => import('../../components/base/NIXModalLayout.vue' /* webpackChunkName: "components/base-n-i-x-modal-layout" */).then(c => wrapFunctional(c.default || c))
export const BaseOverlay = () => import('../../components/base/Overlay.vue' /* webpackChunkName: "components/base-overlay" */).then(c => wrapFunctional(c.default || c))
export const BasePagination = () => import('../../components/base/Pagination.vue' /* webpackChunkName: "components/base-pagination" */).then(c => wrapFunctional(c.default || c))
export const BaseRadio = () => import('../../components/base/Radio.vue' /* webpackChunkName: "components/base-radio" */).then(c => wrapFunctional(c.default || c))
export const BaseRatingBar = () => import('../../components/base/RatingBar.vue' /* webpackChunkName: "components/base-rating-bar" */).then(c => wrapFunctional(c.default || c))
export const BaseRatingBarGroup = () => import('../../components/base/RatingBarGroup.vue' /* webpackChunkName: "components/base-rating-bar-group" */).then(c => wrapFunctional(c.default || c))
export const BaseSelect = () => import('../../components/base/Select.vue' /* webpackChunkName: "components/base-select" */).then(c => wrapFunctional(c.default || c))
export const BaseSidebarLayout = () => import('../../components/base/SidebarLayout.vue' /* webpackChunkName: "components/base-sidebar-layout" */).then(c => wrapFunctional(c.default || c))
export const BaseStarRating = () => import('../../components/base/StarRating.vue' /* webpackChunkName: "components/base-star-rating" */).then(c => wrapFunctional(c.default || c))
export const BaseTextarea = () => import('../../components/base/Textarea.vue' /* webpackChunkName: "components/base-textarea" */).then(c => wrapFunctional(c.default || c))
export const BaseToggle = () => import('../../components/base/Toggle.vue' /* webpackChunkName: "components/base-toggle" */).then(c => wrapFunctional(c.default || c))
export const BaseTouchScroll = () => import('../../components/base/TouchScroll.vue' /* webpackChunkName: "components/base-touch-scroll" */).then(c => wrapFunctional(c.default || c))
export const BaseTransitionOpacity = () => import('../../components/base/TransitionOpacity.vue' /* webpackChunkName: "components/base-transition-opacity" */).then(c => wrapFunctional(c.default || c))
export const BaseVideo = () => import('../../components/base/Video.vue' /* webpackChunkName: "components/base-video" */).then(c => wrapFunctional(c.default || c))
export const AppBackdropOverlay = () => import('../../components/app/BackdropOverlay.vue' /* webpackChunkName: "components/app-backdrop-overlay" */).then(c => wrapFunctional(c.default || c))
export const AppBreadcrumbs = () => import('../../components/app/Breadcrumbs.vue' /* webpackChunkName: "components/app-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const AppCatalog = () => import('../../components/app/Catalog.vue' /* webpackChunkName: "components/app-catalog" */).then(c => wrapFunctional(c.default || c))
export const AppCookiePopup = () => import('../../components/app/CookiePopup.vue' /* webpackChunkName: "components/app-cookie-popup" */).then(c => wrapFunctional(c.default || c))
export const AppFeedbackCompany = () => import('../../components/app/FeedbackCompany.vue' /* webpackChunkName: "components/app-feedback-company" */).then(c => wrapFunctional(c.default || c))
export const AppLangSwitcher = () => import('../../components/app/LangSwitcher.vue' /* webpackChunkName: "components/app-lang-switcher" */).then(c => wrapFunctional(c.default || c))
export const AppLayout = () => import('../../components/app/Layout.vue' /* webpackChunkName: "components/app-layout" */).then(c => wrapFunctional(c.default || c))
export const AppLazyHydrate = () => import('../../components/app/LazyHydrate.vue' /* webpackChunkName: "components/app-lazy-hydrate" */).then(c => wrapFunctional(c.default || c))
export const AppLazyImage = () => import('../../components/app/LazyImage.vue' /* webpackChunkName: "components/app-lazy-image" */).then(c => wrapFunctional(c.default || c))
export const AppLoadingBar = () => import('../../components/app/LoadingBar.vue' /* webpackChunkName: "components/app-loading-bar" */).then(c => wrapFunctional(c.default || c))
export const AppMainLogo = () => import('../../components/app/MainLogo.vue' /* webpackChunkName: "components/app-main-logo" */).then(c => wrapFunctional(c.default || c))
export const AppModal = () => import('../../components/app/Modal.vue' /* webpackChunkName: "components/app-modal" */).then(c => wrapFunctional(c.default || c))
export const AppNoty = () => import('../../components/app/Noty.vue' /* webpackChunkName: "components/app-noty" */).then(c => wrapFunctional(c.default || c))
export const AppPageHeader = () => import('../../components/app/PageHeader.vue' /* webpackChunkName: "components/app-page-header" */).then(c => wrapFunctional(c.default || c))
export const AppSearchPanel = () => import('../../components/app/SearchPanel.vue' /* webpackChunkName: "components/app-search-panel" */).then(c => wrapFunctional(c.default || c))
export const AppSidebar = () => import('../../components/app/Sidebar.vue' /* webpackChunkName: "components/app-sidebar" */).then(c => wrapFunctional(c.default || c))
export const AppSiteBanner = () => import('../../components/app/SiteBanner.vue' /* webpackChunkName: "components/app-site-banner" */).then(c => wrapFunctional(c.default || c))
export const AppSiteBanners = () => import('../../components/app/SiteBanners.vue' /* webpackChunkName: "components/app-site-banners" */).then(c => wrapFunctional(c.default || c))
export const AppUSP = () => import('../../components/app/USP.vue' /* webpackChunkName: "components/app-u-s-p" */).then(c => wrapFunctional(c.default || c))
export const BlocksBannerCardCol3 = () => import('../../components/blocks/BannerCardCol3.vue' /* webpackChunkName: "components/blocks-banner-card-col3" */).then(c => wrapFunctional(c.default || c))
export const BlocksBannerCardLooseCol3 = () => import('../../components/blocks/BannerCardLooseCol3.vue' /* webpackChunkName: "components/blocks-banner-card-loose-col3" */).then(c => wrapFunctional(c.default || c))
export const BlocksBannerLooseCol2 = () => import('../../components/blocks/BannerLooseCol2.vue' /* webpackChunkName: "components/blocks-banner-loose-col2" */).then(c => wrapFunctional(c.default || c))
export const BlocksBannerLooseCol2Folders = () => import('../../components/blocks/BannerLooseCol2Folders.vue' /* webpackChunkName: "components/blocks-banner-loose-col2-folders" */).then(c => wrapFunctional(c.default || c))
export const BlocksBannerLooseCol3 = () => import('../../components/blocks/BannerLooseCol3.vue' /* webpackChunkName: "components/blocks-banner-loose-col3" */).then(c => wrapFunctional(c.default || c))
export const BlocksBannerTightCol3 = () => import('../../components/blocks/BannerTightCol3.vue' /* webpackChunkName: "components/blocks-banner-tight-col3" */).then(c => wrapFunctional(c.default || c))
export const BlocksBannerWideNarrow = () => import('../../components/blocks/BannerWideNarrow.vue' /* webpackChunkName: "components/blocks-banner-wide-narrow" */).then(c => wrapFunctional(c.default || c))
export const BlocksContent = () => import('../../components/blocks/Content.vue' /* webpackChunkName: "components/blocks-content" */).then(c => wrapFunctional(c.default || c))
export const BlocksDiscountFreeProduct = () => import('../../components/blocks/DiscountFreeProduct.vue' /* webpackChunkName: "components/blocks-discount-free-product" */).then(c => wrapFunctional(c.default || c))
export const BlocksFeaturedProduct = () => import('../../components/blocks/FeaturedProduct.vue' /* webpackChunkName: "components/blocks-featured-product" */).then(c => wrapFunctional(c.default || c))
export const BlocksFooterAlleFolders = () => import('../../components/blocks/FooterAlleFolders.vue' /* webpackChunkName: "components/blocks-footer-alle-folders" */).then(c => wrapFunctional(c.default || c))
export const BlocksIframeVideo = () => import('../../components/blocks/IframeVideo.vue' /* webpackChunkName: "components/blocks-iframe-video" */).then(c => wrapFunctional(c.default || c))
export const BlocksImage30Text70 = () => import('../../components/blocks/Image30Text70.vue' /* webpackChunkName: "components/blocks-image30-text70" */).then(c => wrapFunctional(c.default || c))
export const BlocksImage30TextList70 = () => import('../../components/blocks/Image30TextList70.vue' /* webpackChunkName: "components/blocks-image30-text-list70" */).then(c => wrapFunctional(c.default || c))
export const BlocksImage50Text50 = () => import('../../components/blocks/Image50Text50.vue' /* webpackChunkName: "components/blocks-image50-text50" */).then(c => wrapFunctional(c.default || c))
export const BlocksImage70Text30 = () => import('../../components/blocks/Image70Text30.vue' /* webpackChunkName: "components/blocks-image70-text30" */).then(c => wrapFunctional(c.default || c))
export const BlocksImageCol1 = () => import('../../components/blocks/ImageCol1.vue' /* webpackChunkName: "components/blocks-image-col1" */).then(c => wrapFunctional(c.default || c))
export const BlocksImageCol2 = () => import('../../components/blocks/ImageCol2.vue' /* webpackChunkName: "components/blocks-image-col2" */).then(c => wrapFunctional(c.default || c))
export const BlocksImageCol3 = () => import('../../components/blocks/ImageCol3.vue' /* webpackChunkName: "components/blocks-image-col3" */).then(c => wrapFunctional(c.default || c))
export const BlocksImageCol4 = () => import('../../components/blocks/ImageCol4.vue' /* webpackChunkName: "components/blocks-image-col4" */).then(c => wrapFunctional(c.default || c))
export const BlocksNewsCols3 = () => import('../../components/blocks/NewsCols3.vue' /* webpackChunkName: "components/blocks-news-cols3" */).then(c => wrapFunctional(c.default || c))
export const BlocksNewsletter = () => import('../../components/blocks/Newsletter.vue' /* webpackChunkName: "components/blocks-newsletter" */).then(c => wrapFunctional(c.default || c))
export const BlocksSliderBanner = () => import('../../components/blocks/SliderBanner.vue' /* webpackChunkName: "components/blocks-slider-banner" */).then(c => wrapFunctional(c.default || c))
export const BlocksSliderBannerCard = () => import('../../components/blocks/SliderBannerCard.vue' /* webpackChunkName: "components/blocks-slider-banner-card" */).then(c => wrapFunctional(c.default || c))
export const BlocksSliderBannerText = () => import('../../components/blocks/SliderBannerText.vue' /* webpackChunkName: "components/blocks-slider-banner-text" */).then(c => wrapFunctional(c.default || c))
export const BlocksSliderBrands = () => import('../../components/blocks/SliderBrands.vue' /* webpackChunkName: "components/blocks-slider-brands" */).then(c => wrapFunctional(c.default || c))
export const BlocksSliderProduct = () => import('../../components/blocks/SliderProduct.vue' /* webpackChunkName: "components/blocks-slider-product" */).then(c => wrapFunctional(c.default || c))
export const BlocksSliderProductText = () => import('../../components/blocks/SliderProductText.vue' /* webpackChunkName: "components/blocks-slider-product-text" */).then(c => wrapFunctional(c.default || c))
export const BlocksText30Image70 = () => import('../../components/blocks/Text30Image70.vue' /* webpackChunkName: "components/blocks-text30-image70" */).then(c => wrapFunctional(c.default || c))
export const BlocksText50Image50 = () => import('../../components/blocks/Text50Image50.vue' /* webpackChunkName: "components/blocks-text50-image50" */).then(c => wrapFunctional(c.default || c))
export const BlocksText70Image30 = () => import('../../components/blocks/Text70Image30.vue' /* webpackChunkName: "components/blocks-text70-image30" */).then(c => wrapFunctional(c.default || c))
export const BlocksTextList = () => import('../../components/blocks/TextList.vue' /* webpackChunkName: "components/blocks-text-list" */).then(c => wrapFunctional(c.default || c))
export const BlocksTextList70Image30 = () => import('../../components/blocks/TextList70Image30.vue' /* webpackChunkName: "components/blocks-text-list70-image30" */).then(c => wrapFunctional(c.default || c))
export const BlocksTitleCol1Text = () => import('../../components/blocks/TitleCol1Text.vue' /* webpackChunkName: "components/blocks-title-col1-text" */).then(c => wrapFunctional(c.default || c))
export const BlocksTitleCol2Text = () => import('../../components/blocks/TitleCol2Text.vue' /* webpackChunkName: "components/blocks-title-col2-text" */).then(c => wrapFunctional(c.default || c))
export const BlocksTitleCol3Text = () => import('../../components/blocks/TitleCol3Text.vue' /* webpackChunkName: "components/blocks-title-col3-text" */).then(c => wrapFunctional(c.default || c))
export const BlocksUniflip = () => import('../../components/blocks/Uniflip.vue' /* webpackChunkName: "components/blocks-uniflip" */).then(c => wrapFunctional(c.default || c))
export const CartActiveVouchers = () => import('../../components/cart/ActiveVouchers.vue' /* webpackChunkName: "components/cart-active-vouchers" */).then(c => wrapFunctional(c.default || c))
export const CartDeliveryPossibilities = () => import('../../components/cart/DeliveryPossibilities.vue' /* webpackChunkName: "components/cart-delivery-possibilities" */).then(c => wrapFunctional(c.default || c))
export const CartEmptyBox = () => import('../../components/cart/EmptyBox.vue' /* webpackChunkName: "components/cart-empty-box" */).then(c => wrapFunctional(c.default || c))
export const CartFavorite = () => import('../../components/cart/Favorite.vue' /* webpackChunkName: "components/cart-favorite" */).then(c => wrapFunctional(c.default || c))
export const CartLine = () => import('../../components/cart/Line.vue' /* webpackChunkName: "components/cart-line" */).then(c => wrapFunctional(c.default || c))
export const CartLines = () => import('../../components/cart/Lines.vue' /* webpackChunkName: "components/cart-lines" */).then(c => wrapFunctional(c.default || c))
export const CartPaymentVouchers = () => import('../../components/cart/PaymentVouchers.vue' /* webpackChunkName: "components/cart-payment-vouchers" */).then(c => wrapFunctional(c.default || c))
export const CartProductVouchers = () => import('../../components/cart/ProductVouchers.vue' /* webpackChunkName: "components/cart-product-vouchers" */).then(c => wrapFunctional(c.default || c))
export const CartShippingEstimation = () => import('../../components/cart/ShippingEstimation.vue' /* webpackChunkName: "components/cart-shipping-estimation" */).then(c => wrapFunctional(c.default || c))
export const CartStockWarning = () => import('../../components/cart/StockWarning.vue' /* webpackChunkName: "components/cart-stock-warning" */).then(c => wrapFunctional(c.default || c))
export const CartSubmitButton = () => import('../../components/cart/SubmitButton.vue' /* webpackChunkName: "components/cart-submit-button" */).then(c => wrapFunctional(c.default || c))
export const CartTotal = () => import('../../components/cart/Total.vue' /* webpackChunkName: "components/cart-total" */).then(c => wrapFunctional(c.default || c))
export const CartUsefulBox = () => import('../../components/cart/UsefulBox.vue' /* webpackChunkName: "components/cart-useful-box" */).then(c => wrapFunctional(c.default || c))
export const BrandImageItem = () => import('../../components/brand/ImageItem.vue' /* webpackChunkName: "components/brand-image-item" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAlternativeAddress = () => import('../../components/checkout/AlternativeAddress.vue' /* webpackChunkName: "components/checkout-alternative-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCartSummaryBox = () => import('../../components/checkout/CartSummaryBox.vue' /* webpackChunkName: "components/checkout-cart-summary-box" */).then(c => wrapFunctional(c.default || c))
export const CheckoutCustomerAddress = () => import('../../components/checkout/CustomerAddress.vue' /* webpackChunkName: "components/checkout-customer-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDelivery = () => import('../../components/checkout/Delivery.vue' /* webpackChunkName: "components/checkout-delivery" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryAlternativeAddress = () => import('../../components/checkout/DeliveryAlternativeAddress.vue' /* webpackChunkName: "components/checkout-delivery-alternative-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutDeliveryMyAddress = () => import('../../components/checkout/DeliveryMyAddress.vue' /* webpackChunkName: "components/checkout-delivery-my-address" */).then(c => wrapFunctional(c.default || c))
export const CheckoutInformation = () => import('../../components/checkout/Information.vue' /* webpackChunkName: "components/checkout-information" */).then(c => wrapFunctional(c.default || c))
export const CheckoutOverview = () => import('../../components/checkout/Overview.vue' /* webpackChunkName: "components/checkout-overview" */).then(c => wrapFunctional(c.default || c))
export const CheckoutOverviewBox = () => import('../../components/checkout/OverviewBox.vue' /* webpackChunkName: "components/checkout-overview-box" */).then(c => wrapFunctional(c.default || c))
export const CheckoutOverviewCartLine = () => import('../../components/checkout/OverviewCartLine.vue' /* webpackChunkName: "components/checkout-overview-cart-line" */).then(c => wrapFunctional(c.default || c))
export const CheckoutOverviewCartTotal = () => import('../../components/checkout/OverviewCartTotal.vue' /* webpackChunkName: "components/checkout-overview-cart-total" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPayment = () => import('../../components/checkout/Payment.vue' /* webpackChunkName: "components/checkout-payment" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaymentRadio = () => import('../../components/checkout/PaymentRadio.vue' /* webpackChunkName: "components/checkout-payment-radio" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPickup = () => import('../../components/checkout/Pickup.vue' /* webpackChunkName: "components/checkout-pickup" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPickupLocation = () => import('../../components/checkout/PickupLocation.vue' /* webpackChunkName: "components/checkout-pickup-location" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPickupMyStore = () => import('../../components/checkout/PickupMyStore.vue' /* webpackChunkName: "components/checkout-pickup-my-store" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPickupOtherStore = () => import('../../components/checkout/PickupOtherStore.vue' /* webpackChunkName: "components/checkout-pickup-other-store" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSteps = () => import('../../components/checkout/Steps.vue' /* webpackChunkName: "components/checkout-steps" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSuccess = () => import('../../components/checkout/Success.vue' /* webpackChunkName: "components/checkout-success" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSuccessBox = () => import('../../components/checkout/SuccessBox.vue' /* webpackChunkName: "components/checkout-success-box" */).then(c => wrapFunctional(c.default || c))
export const BlogArticle = () => import('../../components/blog/Article.vue' /* webpackChunkName: "components/blog-article" */).then(c => wrapFunctional(c.default || c))
export const BlogLayout = () => import('../../components/blog/Layout.vue' /* webpackChunkName: "components/blog-layout" */).then(c => wrapFunctional(c.default || c))
export const BlogNewsArticles = () => import('../../components/blog/NewsArticles.vue' /* webpackChunkName: "components/blog-news-articles" */).then(c => wrapFunctional(c.default || c))
export const BlogSideMenu = () => import('../../components/blog/SideMenu.vue' /* webpackChunkName: "components/blog-side-menu" */).then(c => wrapFunctional(c.default || c))
export const BlogTagArticles = () => import('../../components/blog/TagArticles.vue' /* webpackChunkName: "components/blog-tag-articles" */).then(c => wrapFunctional(c.default || c))
export const ContactAdvice = () => import('../../components/contact/Advice.vue' /* webpackChunkName: "components/contact-advice" */).then(c => wrapFunctional(c.default || c))
export const ContactSocialLinks = () => import('../../components/contact/SocialLinks.vue' /* webpackChunkName: "components/contact-social-links" */).then(c => wrapFunctional(c.default || c))
export const FaqQuestion = () => import('../../components/faq/Question.vue' /* webpackChunkName: "components/faq-question" */).then(c => wrapFunctional(c.default || c))
export const ContentsAssembly = () => import('../../components/contents/Assembly.vue' /* webpackChunkName: "components/contents-assembly" */).then(c => wrapFunctional(c.default || c))
export const ContentsBlocks = () => import('../../components/contents/Blocks.vue' /* webpackChunkName: "components/contents-blocks" */).then(c => wrapFunctional(c.default || c))
export const FormAddress = () => import('../../components/form/Address.vue' /* webpackChunkName: "components/form-address" */).then(c => wrapFunctional(c.default || c))
export const FormCartPromocode = () => import('../../components/form/CartPromocode.vue' /* webpackChunkName: "components/form-cart-promocode" */).then(c => wrapFunctional(c.default || c))
export const FormForgotPassword = () => import('../../components/form/ForgotPassword.vue' /* webpackChunkName: "components/form-forgot-password" */).then(c => wrapFunctional(c.default || c))
export const FormLogin = () => import('../../components/form/Login.vue' /* webpackChunkName: "components/form-login" */).then(c => wrapFunctional(c.default || c))
export const FormRegister = () => import('../../components/form/Register.vue' /* webpackChunkName: "components/form-register" */).then(c => wrapFunctional(c.default || c))
export const FormResetPassword = () => import('../../components/form/ResetPassword.vue' /* webpackChunkName: "components/form-reset-password" */).then(c => wrapFunctional(c.default || c))
export const FormReview = () => import('../../components/form/Review.vue' /* webpackChunkName: "components/form-review" */).then(c => wrapFunctional(c.default || c))
export const ModalAddToCart = () => import('../../components/modal/AddToCart.vue' /* webpackChunkName: "components/modal-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const ModalAddress = () => import('../../components/modal/Address.vue' /* webpackChunkName: "components/modal-address" */).then(c => wrapFunctional(c.default || c))
export const ModalIngredients = () => import('../../components/modal/Ingredients.vue' /* webpackChunkName: "components/modal-ingredients" */).then(c => wrapFunctional(c.default || c))
export const ModalNIX18 = () => import('../../components/modal/NIX18.vue' /* webpackChunkName: "components/modal-n-i-x18" */).then(c => wrapFunctional(c.default || c))
export const ModalProductSizeSelection = () => import('../../components/modal/ProductSizeSelection.vue' /* webpackChunkName: "components/modal-product-size-selection" */).then(c => wrapFunctional(c.default || c))
export const ModalRemoveFavorite = () => import('../../components/modal/RemoveFavorite.vue' /* webpackChunkName: "components/modal-remove-favorite" */).then(c => wrapFunctional(c.default || c))
export const ModalStock = () => import('../../components/modal/Stock.vue' /* webpackChunkName: "components/modal-stock" */).then(c => wrapFunctional(c.default || c))
export const OrderItemDetail = () => import('../../components/order/ItemDetail.vue' /* webpackChunkName: "components/order-item-detail" */).then(c => wrapFunctional(c.default || c))
export const OrderLine = () => import('../../components/order/Line.vue' /* webpackChunkName: "components/order-line" */).then(c => wrapFunctional(c.default || c))
export const OrderWithoutAccount = () => import('../../components/order/WithoutAccount.vue' /* webpackChunkName: "components/order-without-account" */).then(c => wrapFunctional(c.default || c))
export const ProductAddToCartButton = () => import('../../components/product/AddToCartButton.vue' /* webpackChunkName: "components/product-add-to-cart-button" */).then(c => wrapFunctional(c.default || c))
export const ProductAddToCartButtonQuantity = () => import('../../components/product/AddToCartButtonQuantity.vue' /* webpackChunkName: "components/product-add-to-cart-button-quantity" */).then(c => wrapFunctional(c.default || c))
export const ProductAltDiscountTile = () => import('../../components/product/AltDiscountTile.vue' /* webpackChunkName: "components/product-alt-discount-tile" */).then(c => wrapFunctional(c.default || c))
export const ProductBadge = () => import('../../components/product/Badge.vue' /* webpackChunkName: "components/product-badge" */).then(c => wrapFunctional(c.default || c))
export const ProductDiscountLabels = () => import('../../components/product/DiscountLabels.vue' /* webpackChunkName: "components/product-discount-labels" */).then(c => wrapFunctional(c.default || c))
export const ProductFeaturedTile = () => import('../../components/product/FeaturedTile.vue' /* webpackChunkName: "components/product-featured-tile" */).then(c => wrapFunctional(c.default || c))
export const ProductLabel = () => import('../../components/product/Label.vue' /* webpackChunkName: "components/product-label" */).then(c => wrapFunctional(c.default || c))
export const ProductPrice = () => import('../../components/product/Price.vue' /* webpackChunkName: "components/product-price" */).then(c => wrapFunctional(c.default || c))
export const ProductPriceSlider = () => import('../../components/product/PriceSlider.vue' /* webpackChunkName: "components/product-price-slider" */).then(c => wrapFunctional(c.default || c))
export const ProductRating = () => import('../../components/product/Rating.vue' /* webpackChunkName: "components/product-rating" */).then(c => wrapFunctional(c.default || c))
export const ProductRatingBarGroup = () => import('../../components/product/RatingBarGroup.vue' /* webpackChunkName: "components/product-rating-bar-group" */).then(c => wrapFunctional(c.default || c))
export const ProductReview = () => import('../../components/product/Review.vue' /* webpackChunkName: "components/product-review" */).then(c => wrapFunctional(c.default || c))
export const ProductSpecialNode = () => import('../../components/product/SpecialNode.vue' /* webpackChunkName: "components/product-special-node" */).then(c => wrapFunctional(c.default || c))
export const ProductTags = () => import('../../components/product/Tags.vue' /* webpackChunkName: "components/product-tags" */).then(c => wrapFunctional(c.default || c))
export const ProductTile = () => import('../../components/product/Tile.vue' /* webpackChunkName: "components/product-tile" */).then(c => wrapFunctional(c.default || c))
export const ProductTileQuantity = () => import('../../components/product/TileQuantity.vue' /* webpackChunkName: "components/product-tile-quantity" */).then(c => wrapFunctional(c.default || c))
export const ProductVariantOptions = () => import('../../components/product/VariantOptions.vue' /* webpackChunkName: "components/product-variant-options" */).then(c => wrapFunctional(c.default || c))
export const SidebarProductFilter = () => import('../../components/sidebar/ProductFilter.vue' /* webpackChunkName: "components/sidebar-product-filter" */).then(c => wrapFunctional(c.default || c))
export const SkeletonBrandList = () => import('../../components/skeleton/BrandList.vue' /* webpackChunkName: "components/skeleton-brand-list" */).then(c => wrapFunctional(c.default || c))
export const StoreCard = () => import('../../components/store/Card.vue' /* webpackChunkName: "components/store-card" */).then(c => wrapFunctional(c.default || c))
export const StoreGallery = () => import('../../components/store/Gallery.vue' /* webpackChunkName: "components/store-gallery" */).then(c => wrapFunctional(c.default || c))
export const StoreInfo = () => import('../../components/store/Info.vue' /* webpackChunkName: "components/store-info" */).then(c => wrapFunctional(c.default || c))
export const StoreLocationSelection = () => import('../../components/store/LocationSelection.vue' /* webpackChunkName: "components/store-location-selection" */).then(c => wrapFunctional(c.default || c))
export const StoreOpeningTimes = () => import('../../components/store/OpeningTimes.vue' /* webpackChunkName: "components/store-opening-times" */).then(c => wrapFunctional(c.default || c))
export const StoreSearchInput = () => import('../../components/store/SearchInput.vue' /* webpackChunkName: "components/store-search-input" */).then(c => wrapFunctional(c.default || c))
export const StoreSearchPanel = () => import('../../components/store/SearchPanel.vue' /* webpackChunkName: "components/store-search-panel" */).then(c => wrapFunctional(c.default || c))
export const StoreSelection = () => import('../../components/store/Selection.vue' /* webpackChunkName: "components/store-selection" */).then(c => wrapFunctional(c.default || c))
export const StoreStockCard = () => import('../../components/store/StockCard.vue' /* webpackChunkName: "components/store-stock-card" */).then(c => wrapFunctional(c.default || c))
export const StoreVariantCard = () => import('../../components/store/VariantCard.vue' /* webpackChunkName: "components/store-variant-card" */).then(c => wrapFunctional(c.default || c))
export const TransactionRecord = () => import('../../components/transaction/record.vue' /* webpackChunkName: "components/transaction-record" */).then(c => wrapFunctional(c.default || c))
export const WidgetsUSP = () => import('../../components/widgets/USP.vue' /* webpackChunkName: "components/widgets-u-s-p" */).then(c => wrapFunctional(c.default || c))
export const WishlistLine = () => import('../../components/wishlist/Line.vue' /* webpackChunkName: "components/wishlist-line" */).then(c => wrapFunctional(c.default || c))
export const BaseRangeSliderButton = () => import('../../components/base/range-slider/Button.vue' /* webpackChunkName: "components/base-range-slider-button" */).then(c => wrapFunctional(c.default || c))
export const BaseRangeSliderInputNumber = () => import('../../components/base/range-slider/InputNumber.vue' /* webpackChunkName: "components/base-range-slider-input-number" */).then(c => wrapFunctional(c.default || c))
export const BaseRangeSlider = () => import('../../components/base/range-slider/index.vue' /* webpackChunkName: "components/base-range-slider" */).then(c => wrapFunctional(c.default || c))
export const AppFooterTop = () => import('../../components/app/footer/Top.vue' /* webpackChunkName: "components/app-footer-top" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/app/footer/index.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderBasic = () => import('../../components/app/header/Basic.vue' /* webpackChunkName: "components/app-header-basic" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderCartLine = () => import('../../components/app/header/CartLine.vue' /* webpackChunkName: "components/app-header-cart-line" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderCartSummary = () => import('../../components/app/header/CartSummary.vue' /* webpackChunkName: "components/app-header-cart-summary" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderCartTotal = () => import('../../components/app/header/CartTotal.vue' /* webpackChunkName: "components/app-header-cart-total" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderFavorite = () => import('../../components/app/header/Favorite.vue' /* webpackChunkName: "components/app-header-favorite" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderMenuBar = () => import('../../components/app/header/MenuBar.vue' /* webpackChunkName: "components/app-header-menu-bar" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderMenuBtn = () => import('../../components/app/header/MenuBtn.vue' /* webpackChunkName: "components/app-header-menu-btn" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderMenuDesktop = () => import('../../components/app/header/MenuDesktop.vue' /* webpackChunkName: "components/app-header-menu-desktop" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderNavItem = () => import('../../components/app/header/NavItem.vue' /* webpackChunkName: "components/app-header-nav-item" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderNavPopover = () => import('../../components/app/header/NavPopover.vue' /* webpackChunkName: "components/app-header-nav-popover" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderNavPopoverGroup = () => import('../../components/app/header/NavPopoverGroup.vue' /* webpackChunkName: "components/app-header-nav-popover-group" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderQuickLinks = () => import('../../components/app/header/QuickLinks.vue' /* webpackChunkName: "components/app-header-quick-links" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderSearchInput = () => import('../../components/app/header/SearchInput.vue' /* webpackChunkName: "components/app-header-search-input" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderShoppingCartBtn = () => import('../../components/app/header/ShoppingCartBtn.vue' /* webpackChunkName: "components/app-header-shopping-cart-btn" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderStoreBtn = () => import('../../components/app/header/StoreBtn.vue' /* webpackChunkName: "components/app-header-store-btn" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderUserDropdown = () => import('../../components/app/header/UserDropdown.vue' /* webpackChunkName: "components/app-header-user-dropdown" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/app/header/index.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const AppSidebarAccountMenu = () => import('../../components/app/sidebar/AccountMenu.vue' /* webpackChunkName: "components/app-sidebar-account-menu" */).then(c => wrapFunctional(c.default || c))
export const AppSidebarCatalogMenu = () => import('../../components/app/sidebar/CatalogMenu.vue' /* webpackChunkName: "components/app-sidebar-catalog-menu" */).then(c => wrapFunctional(c.default || c))
export const AppSidebarMenu = () => import('../../components/app/sidebar/Menu.vue' /* webpackChunkName: "components/app-sidebar-menu" */).then(c => wrapFunctional(c.default || c))
export const AppSidebarSubMenu = () => import('../../components/app/sidebar/SubMenu.vue' /* webpackChunkName: "components/app-sidebar-sub-menu" */).then(c => wrapFunctional(c.default || c))
export const AppSidebarTopMenu = () => import('../../components/app/sidebar/TopMenu.vue' /* webpackChunkName: "components/app-sidebar-top-menu" */).then(c => wrapFunctional(c.default || c))
export const BlocksDynamicForm = () => import('../../components/blocks/dynamic/Form.vue' /* webpackChunkName: "components/blocks-dynamic-form" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPickupLocationsCard = () => import('../../components/checkout/pickup-locations/Card.vue' /* webpackChunkName: "components/checkout-pickup-locations-card" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPickupLocationsSearchInput = () => import('../../components/checkout/pickup-locations/SearchInput.vue' /* webpackChunkName: "components/checkout-pickup-locations-search-input" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPickupLocationsSearchPanel = () => import('../../components/checkout/pickup-locations/SearchPanel.vue' /* webpackChunkName: "components/checkout-pickup-locations-search-panel" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPickupLocationsSelection = () => import('../../components/checkout/pickup-locations/Selection.vue' /* webpackChunkName: "components/checkout-pickup-locations-selection" */).then(c => wrapFunctional(c.default || c))
export const ProductBlockLister = () => import('../../components/product/block/Lister.vue' /* webpackChunkName: "components/product-block-lister" */).then(c => wrapFunctional(c.default || c))
export const ProductBlockSummary = () => import('../../components/product/block/Summary.vue' /* webpackChunkName: "components/product-block-summary" */).then(c => wrapFunctional(c.default || c))
export const ProductBlockViewedProducts = () => import('../../components/product/block/ViewedProducts.vue' /* webpackChunkName: "components/product-block-viewed-products" */).then(c => wrapFunctional(c.default || c))
export const ProductListerAbout = () => import('../../components/product/lister/About.vue' /* webpackChunkName: "components/product-lister-about" */).then(c => wrapFunctional(c.default || c))
export const ProductListerBanner = () => import('../../components/product/lister/Banner.vue' /* webpackChunkName: "components/product-lister-banner" */).then(c => wrapFunctional(c.default || c))
export const ProductListerCategoryTiles = () => import('../../components/product/lister/CategoryTiles.vue' /* webpackChunkName: "components/product-lister-category-tiles" */).then(c => wrapFunctional(c.default || c))
export const ProductListerFavorite = () => import('../../components/product/lister/Favorite.vue' /* webpackChunkName: "components/product-lister-favorite" */).then(c => wrapFunctional(c.default || c))
export const ProductListerFilter = () => import('../../components/product/lister/Filter.vue' /* webpackChunkName: "components/product-lister-filter" */).then(c => wrapFunctional(c.default || c))
export const ProductListerFilterActiveBar = () => import('../../components/product/lister/FilterActiveBar.vue' /* webpackChunkName: "components/product-lister-filter-active-bar" */).then(c => wrapFunctional(c.default || c))
export const ProductListerPageSize = () => import('../../components/product/lister/PageSize.vue' /* webpackChunkName: "components/product-lister-page-size" */).then(c => wrapFunctional(c.default || c))
export const ProductListerPrice = () => import('../../components/product/lister/Price.vue' /* webpackChunkName: "components/product-lister-price" */).then(c => wrapFunctional(c.default || c))
export const ProductListerSortby = () => import('../../components/product/lister/Sortby.vue' /* webpackChunkName: "components/product-lister-sortby" */).then(c => wrapFunctional(c.default || c))
export const ProductLister = () => import('../../components/product/lister/index.vue' /* webpackChunkName: "components/product-lister" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailAddToCart = () => import('../../components/product/detail/AddToCart.vue' /* webpackChunkName: "components/product-detail-add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailConnections = () => import('../../components/product/detail/Connections.vue' /* webpackChunkName: "components/product-detail-connections" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailConnectionsSlider = () => import('../../components/product/detail/ConnectionsSlider.vue' /* webpackChunkName: "components/product-detail-connections-slider" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailFavorite = () => import('../../components/product/detail/Favorite.vue' /* webpackChunkName: "components/product-detail-favorite" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailGallery = () => import('../../components/product/detail/Gallery.vue' /* webpackChunkName: "components/product-detail-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailGenericSelection = () => import('../../components/product/detail/GenericSelection.vue' /* webpackChunkName: "components/product-detail-generic-selection" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailPrice = () => import('../../components/product/detail/Price.vue' /* webpackChunkName: "components/product-detail-price" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailSizeSelection = () => import('../../components/product/detail/SizeSelection.vue' /* webpackChunkName: "components/product-detail-size-selection" */).then(c => wrapFunctional(c.default || c))
export const StoreCardLayout = () => import('../../components/store/card/Layout.vue' /* webpackChunkName: "components/store-card-layout" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailInformationIngredients = () => import('../../components/product/detail/information/Ingredients.vue' /* webpackChunkName: "components/product-detail-information-ingredients" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailInformationIntroduction = () => import('../../components/product/detail/information/Introduction.vue' /* webpackChunkName: "components/product-detail-information-introduction" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailInformationMobile = () => import('../../components/product/detail/information/Mobile.vue' /* webpackChunkName: "components/product-detail-information-mobile" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailInformationReviews = () => import('../../components/product/detail/information/Reviews.vue' /* webpackChunkName: "components/product-detail-information-reviews" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailInformationSpecifications = () => import('../../components/product/detail/information/Specifications.vue' /* webpackChunkName: "components/product-detail-information-specifications" */).then(c => wrapFunctional(c.default || c))
export const ProductDetailInformation = () => import('../../components/product/detail/information/index.vue' /* webpackChunkName: "components/product-detail-information" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
